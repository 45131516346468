import { FieldPreset } from '../../../../../constants/field-types'
import { makeGeneralText } from './definitions/general-text'
import { makeGeneralNumber } from './definitions/general-number'
import { makeGeneralURL } from './definitions/general-url'
import { makeGeneralRadioButton } from './definitions/general-radio-group'
import { makeGeneralDatePicker } from './definitions/general-date-picker'
import { makeGeneralDropdown } from './definitions/general-dropdown'
import { makeGeneralUploadButton } from './definitions/general-upload-button'
import { makeGeneralCheckbox } from './definitions/general-checkbox'
import { makeGeneralSingleCheckbox } from './definitions/general-single-checkbox'
import { makeGeneralTextBox } from './definitions/general-text-box'
import { makeGeneralRating } from './definitions/general-rating'
import { makeGeneralSubscribe } from './definitions/general-subscribe'

export const generalFields = () => ({
  [FieldPreset.GENERAL_TEXT]: makeGeneralText(),
  [FieldPreset.GENERAL_NUMBER]: makeGeneralNumber(),
  [FieldPreset.GENERAL_URL]: makeGeneralURL(),
  [FieldPreset.GENERAL_RADIO_BUTTON]: makeGeneralRadioButton(),
  [FieldPreset.GENERAL_DATE_PICKER]: makeGeneralDatePicker(),
  [FieldPreset.GENERAL_DROP_DOWN]: makeGeneralDropdown(),
  [FieldPreset.GENERAL_UPLOAD_BUTTON]: makeGeneralUploadButton(),
  [FieldPreset.GENERAL_CHECKBOX]: makeGeneralCheckbox(),
  [FieldPreset.GENERAL_SINGLE_CHECKBOX]: makeGeneralSingleCheckbox(),
  [FieldPreset.GENERAL_SUBSCRIBE]: makeGeneralSubscribe(),
  [FieldPreset.GENERAL_TEXT_BOX]: makeGeneralTextBox(),
  [FieldPreset.GENERAL_RATING]: makeGeneralRating(),
})
