import * as _ from 'lodash'
import { makeGeneralURL } from '../../general-fields/definitions/general-url'
import { Field } from '../../field-types-data'
import { CRM_TYPES } from '../../../../../../constants/crm-types-tags'

export const makeCrmURL = (): Field => {
  return _.merge(makeGeneralURL(), {
    extraData: {
      connectionConfig: {
        crmType: CRM_TYPES.CUSTOM_FIELD,
      },
    },
  })
}
