import { FieldPreset } from '../../../../../../constants/field-types'
import { FIELDS } from '../../../../../../constants/roles'
import translations from '../../../../services/translations'
import { Field } from '../../field-types-data'
import { makeGeneralSingleCheckbox } from './general-single-checkbox'
import * as _ from 'lodash'

export const makeGeneralSubscribe = (): Field => {
  const t = translations.t.bind(translations)

  return _.merge(makeGeneralSingleCheckbox(), {
    extraData: {
      role: FIELDS.ROLE_FIELD_SUBSCRIBE,
      connectionConfig: {
        crmLabel: t(`fieldTypes.${FieldPreset.GENERAL_SUBSCRIBE}`),
      },
      data: {
        label: t(`preset.${FieldPreset.GENERAL_SUBSCRIBE}Label`),
      },
      layout: {
        width: 290,
      },
    },
  })
}
