import * as _ from 'lodash'
import { FieldPreset } from '../../../../../../constants/field-types'
import { makeGeneralDatePicker } from '../../general-fields/definitions/general-date-picker'
import translations from '../../../../services/translations'
import { Field } from '../../field-types-data'
import { CRM_TYPES, CRM_TAGS } from '../../../../../../constants/crm-types-tags'

export const makeAnniversary = (): Field => {
  const t = translations.t.bind(translations)

  return _.merge(makeGeneralDatePicker(), {
    extraData: {
      connectionConfig: {
        crmType: CRM_TYPES.DATE,
        crmTag: CRM_TAGS.ANNIVERSARY,
        crmLabel: t(`fieldTypes.${FieldPreset.ANNIVERSARY}`),
      },
      props: { placeholder: t(`fieldTypes.${FieldPreset.ANNIVERSARY}`) },
    },
  })
}
