export const fetcher = () => {
  let resolveCall
  const fetcherPromise: Promise<any> = new Promise(resolve => {
    resolveCall = resolve
  })
  return {
    resolveData: resolveCall,
    getData: fetcherPromise,
  }
}
