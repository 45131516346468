import * as _ from 'lodash'
import { getFieldData } from './fields/field-types-data'
import { getPresets } from './presets-data'
import * as fields from '../../../assets/presets/fields.json'
import { FormPreset } from '../../../constants/form-types'

const applyFieldData = (field, fieldType, extraData: any = {}) => {
  const fieldProps: any = getFieldData(fieldType)
  return _.merge({}, field, fieldProps.extraData, extraData, {
    connectionConfig: {
      fieldType,
      collectionFieldType: fieldProps.collectionFieldType,
    },
  })
}

export const getFormPreset = (presetKey: FormPreset) => getPresets()[presetKey]

// TODO: Field type is the field preset
export const getFieldPreset = (fieldType, extraData) => {
  const componentType = getFieldData(fieldType).componentType
  return applyFieldData(fields[componentType], fieldType, extraData)
}
