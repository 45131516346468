import * as _ from 'lodash'
import { makeGeneralNumber } from '../../general-fields/definitions/general-number'
import { Field } from '../../field-types-data'
import { CRM_TYPES } from '../../../../../../constants/crm-types-tags'

export const makeCrmNumber = (): Field => {
  return _.merge(makeGeneralNumber(), {
    extraData: {
      connectionConfig: {
        crmType: CRM_TYPES.CUSTOM_FIELD,
      },
    },
  })
}
