import { recommendedFields } from './recommended-fields'
import { generalFields } from './general-fields'
import { registrationFormFields } from './registration-form-fields'
import { FieldCollectionType } from '../../../../constants/wixcode'
import { COMPONENT_TYPES } from './component-types'

export interface Field {
  collectionFieldType: FieldCollectionType | false
  componentType: COMPONENT_TYPES
  extraData: {
    role: string
    connectionConfig?: {
      crmType: string
      crmLabel?: string
      crmTag?: string
    }
    props?: {}
    data?: {}
  }
}

// TODO: Field type is FieldPreset, change in all code for correct typing
export const getFieldData = (fieldType: string): Field => {
  return getAllFieldsData()[fieldType]
}

export const getAllFieldsData = () => ({
  ...recommendedFields(),
  ...generalFields(),
  ...registrationFormFields(),
})
