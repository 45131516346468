import * as _ from 'lodash'
import { FieldPreset } from '../../../../../../constants/field-types'
import { makeGeneralURL } from '../../general-fields/definitions/general-url'
import translations from '../../../../services/translations'
import { Field } from '../../field-types-data'
import { CRM_TYPES, CRM_TAGS } from '../../../../../../constants/crm-types-tags'

export const makeWebsite = (): Field => {
  const t = translations.t.bind(translations)

  return _.merge(makeGeneralURL(), {
    extraData: {
      connectionConfig: {
        crmType: CRM_TYPES.WEBSITE,
        crmTag: CRM_TAGS.OTHER,
        crmLabel: t(`fieldTypes.${FieldPreset.WEBSITE}`),
      },
      props: {
        placeholder: t(`fieldTypes.${FieldPreset.WEBSITE}`),
      },
    },
  })
}
