import { FieldPreset } from '../../../../../../constants/field-types'
import { FIELDS } from '../../../../../../constants/roles'
import { COMPONENT_TYPES } from '../../component-types'
import translations from '../../../../services/translations'
import { Field } from '../../field-types-data'
import { CRM_TYPES } from '../../../../../../constants/crm-types-tags'

export const makePassword = (): Field => {
  const t = translations.t.bind(translations)

  return {
    collectionFieldType: false,
    componentType: COMPONENT_TYPES.TEXT_INPUT,
    extraData: {
      role: FIELDS.ROLE_FIELD_REGISTRATION_FORM_PASSWORD,
      connectionConfig: {
        crmType: CRM_TYPES.CUSTOM_FIELD,
      },
      data: {
        textType: 'password',
        crmLabel: t(`fieldTypes.${FieldPreset.REGISTRATION_FORM_PASSWORD}`),
      },
      props: {
        placeholder: t(`fieldTypes.${FieldPreset.REGISTRATION_FORM_PASSWORD}`),
        required: true,
      },
    },
  }
}
