export const preset = t => ({
  type: 'Container',
  components: [
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 780,
        height: 33,
        x: 60,
        y: 35,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      style: {
        type: 'TopLevelStyle',
        id: 'txtNew',
        metaData: { isPreset: true, schemaVersion: '1.0', isHidden: false },
        style: {
          properties: {
            f0: 'font_0',
            f1: 'font_1',
            f2: 'font_2',
            f3: 'font_3',
            f4: 'font_4',
            f5: 'font_5',
            f6: 'font_6',
            f7: 'font_7',
            f8: 'font_8',
            f9: 'font_9',
            f10: 'font_10',
          },
          propertiesSource: {
            f0: 'theme',
            f1: 'theme',
            f2: 'theme',
            f3: 'theme',
            f4: 'theme',
            f5: 'theme',
            f6: 'theme',
            f7: 'theme',
            f8: 'theme',
            f9: 'theme',
            f10: 'theme',
          },
          groups: {},
        },
        componentClassName: '',
        pageId: '',
        compId: '',
        styleType: 'system',
        skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      },
      role: 'title_Role',
      config: {},
      props: {
        type: 'WRichTextProperties',
        metaData: { schemaVersion: '1.0', autoGenerated: false },
        isHidden: false,
        brightness: 1.0,
        packed: true,
      },
      data: {
        type: 'StyledText',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        text:
          "<h5 class='font_5' style='text-align:center'><<span style='font-size:30px;'>Subscribe to Site</span></h5>",
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 510,
        height: 21,
        x: 200,
        y: 264,
        scale: 1.0,
        rotationInDegrees: 0.0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      style: {
        type: 'TopLevelStyle',
        id: 'txtNew',
        metaData: { isPreset: true, schemaVersion: '1.0', isHidden: false },
        style: {
          properties: {
            f0: 'font_0',
            f1: 'font_1',
            f10: 'font_10',
            f2: 'font_2',
            f3: 'font_3',
            f4: 'font_4',
            f5: 'font_5',
            f6: 'font_6',
            f7: 'font_7',
            f8: 'font_8',
            f9: 'font_9',
          },
          propertiesSource: {
            f0: 'theme',
            f1: 'theme',
            f10: 'theme',
            f2: 'theme',
            f3: 'theme',
            f4: 'theme',
            f5: 'theme',
            f6: 'theme',
            f7: 'theme',
            f8: 'theme',
            f9: 'theme',
          },
          groups: {},
        },
        componentClassName: '',
        pageId: '',
        compId: '',
        styleType: 'system',
        skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      },
      role: 'message_Role',
      config: {},
      props: {
        type: 'WRichTextProperties',
        metaData: { schemaVersion: '1.0', autoGenerated: false },
        isHidden: false,
        brightness: 1.0,
        packed: true,
      },
      data: {
        type: 'StyledText',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        text: `<p class="font_8" style="text-align: center;"><span style="color:#8FCA8F;">${t(
          'preset.successMessageText'
        )}</span></p>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
    },
    {
      type: 'Component',
      layout: {
        width: 142,
        height: 40,
        x: 384,
        y: 214,
        scale: 1.0,
        rotationInDegrees: 0.0,
        fixedPosition: false,
      },
      mobileHintsQuery: 'mobileHints-jatmrd6y',
      componentType: 'wysiwyg.viewer.components.SiteButton',
      style: {
        type: 'TopLevelStyle',
        id: 'style-jatfuivt2',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        style: {
          properties: {
            'alpha-bg': '1',
            'alpha-bgh': '1',
            'alpha-brd': '1',
            'alpha-brdh': '0',
            'alpha-txt': '1',
            'alpha-txth': '1',
            bg: 'color_18',
            bgh: 'color_17',
            'boxShadowToggleOn-shd': 'false',
            brd: 'color_14',
            brdh: 'color_12',
            brw: '0',
            fnt: 'font_8',
            rd: '0px',
            shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
            txt: 'color_11',
            txth: 'color_11',
          },
          propertiesSource: {
            'alpha-brdh': 'value',
            bg: 'theme',
            bgh: 'theme',
            brd: 'theme',
            brdh: 'theme',
            brw: 'value',
            fnt: 'theme',
            rd: 'value',
            shd: 'value',
            txt: 'theme',
            txth: 'theme',
          },
          groups: {},
        },
        componentClassName: '',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.button.BasicButton',
      },
      role: 'button_Role',
      config: {},
      props: {
        type: 'ButtonProperties',
        metaData: { schemaVersion: '1.0' },
        align: 'center',
        margin: 0,
      },
      data: {
        type: 'LinkableButton',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        label: t('preset.submitButtonLabel'),

        link: {
          type: 'FormSubmitButtonLink',
        },
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.appinputs.AppsTextInputSkin',
      layout: {
        width: 240,
        height: 42,
        x: 330,
        y: 113,
        scale: 1.0,
        rotationInDegrees: 0.0,
        fixedPosition: false,
      },
      mobileHintsQuery: 'mobileHints-jatmrd7b',
      componentType: 'wysiwyg.viewer.components.inputs.TextInput',
      style: {
        type: 'TopLevelStyle',
        id: 'style-jausp9as',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        style: {
          properties: {
            'alpha-bg': '1',
            'alpha-bgd': '1',
            'alpha-bge': '1',
            'alpha-bgf': '1',
            'alpha-bgh': '1',
            'alpha-brd': '0.55',
            'alpha-brde': '0.6',
            'alpha-brdf': '1',
            'alpha-brdh': '0.7',
            bg: 'color_11',
            bgd: '#FFFFFF',
            bge: 'color_11',
            bgf: 'color_11',
            bgh: 'color_11',
            'boxShadowToggleOn-shd': 'false',
            brd: 'color_15',
            brdd: '#DBDBDB',
            brde: '#FF4040',
            brdf: 'color_18',
            brdh: 'color_15',
            brw: '1',
            fnt: 'font_8',
            rd: '0px',
            shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
            txt: 'color_15',
            txt2: 'color_14',
            txtd: '#DBDBDB',
          },
          propertiesSource: {
            'alpha-bg': 'theme',
            'alpha-bgd': 'theme',
            'alpha-bge': 'theme',
            'alpha-bgf': 'theme',
            'alpha-bgh': 'theme',
            'alpha-brd': 'value',
            'alpha-brde': 'theme',
            'alpha-brdf': 'value',
            'alpha-brdh': 'value',
            bg: 'theme',
            bge: 'theme',
            bgf: 'theme',
            bgh: 'theme',
            brd: 'theme',
            brde: 'theme',
            brdf: 'theme',
            brdh: 'theme',
            fnt: 'theme',
            txt: 'theme',
            txt2: 'theme',
          },
        },
        componentClassName: 'wysiwyg.viewer.components.inputs.TextInput',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.appinputs.AppsTextInputSkin',
      },
      role: 'field_role_text',
      config: {
        crmLabel: t('preset.emailCrmLabel'),
        fieldType: 'email',
        crmType: 'email',
        crmTag: 'main',
      },
      props: {
        type: 'TextInputProperties',
        metaData: { schemaVersion: '1.0', autoGenerated: false },
        required: true,
        textAlignment: 'left',
        textPadding: 12,
        placeholder: t('preset.emailCrmLabel'),

        defaultTextType: 'placeholderText',
      },
      data: {
        type: 'TextInput',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        value: '',
        textType: 'email',
        maxLength: 250,
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.appinputs.AppsTextInputSkin',
      layout: {
        width: 240,
        height: 42,
        x: 600,
        y: 113,
        scale: 1.0,
        rotationInDegrees: 0.0,
        fixedPosition: false,
      },
      mobileHintsQuery: 'mobileHints-jatmrd7i1',
      componentType: 'wysiwyg.viewer.components.inputs.TextInput',
      style: {
        type: 'TopLevelStyle',
        id: 'style-jauspgsi',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        style: {
          properties: {
            'alpha-bg': '1',
            'alpha-bgd': '1',
            'alpha-bge': '1',
            'alpha-bgf': '1',
            'alpha-bgh': '1',
            'alpha-brd': '0.55',
            'alpha-brde': '0.6',
            'alpha-brdf': '1',
            'alpha-brdh': '0.7',
            bg: 'color_11',
            bgd: '#FFFFFF',
            bge: 'color_11',
            bgf: 'color_11',
            bgh: 'color_11',
            'boxShadowToggleOn-shd': 'false',
            brd: 'color_15',
            brdd: '#DBDBDB',
            brde: '#FF4040',
            brdf: 'color_18',
            brdh: 'color_15',
            brw: '1',
            fnt: 'font_8',
            rd: '0px',
            shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
            txt: 'color_15',
            txt2: 'color_14',
            txtd: '#DBDBDB',
          },
          propertiesSource: {
            'alpha-bg': 'theme',
            'alpha-bgd': 'theme',
            'alpha-bge': 'theme',
            'alpha-bgf': 'theme',
            'alpha-bgh': 'theme',
            'alpha-brd': 'value',
            'alpha-brde': 'theme',
            'alpha-brdf': 'value',
            'alpha-brdh': 'value',
            bg: 'theme',
            bge: 'theme',
            bgf: 'theme',
            bgh: 'theme',
            brd: 'theme',
            brde: 'theme',
            brdf: 'theme',
            brdh: 'theme',
            fnt: 'theme',
            txt: 'theme',
            txt2: 'theme',
          },
        },
        componentClassName: 'wysiwyg.viewer.components.inputs.TextInput',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.appinputs.AppsTextInputSkin',
      },
      role: 'field_role_text',
      config: {
        crmLabel: t('preset.phoneCrmLabel'),
        fieldType: 'phone',
        crmType: 'phone',
        crmTag: 'main',
      },
      props: {
        type: 'TextInputProperties',
        metaData: { schemaVersion: '1.0', autoGenerated: false },
        required: false,
        textAlignment: 'left',
        textPadding: 12,
        placeholder: t('preset.phoneCrmLabel'),

        defaultTextType: 'placeholderText',
      },
      data: {
        type: 'TextInput',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        value: '',
        textType: 'tel',
        maxLength: 50,
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.appinputs.AppsTextInputSkin',
      layout: {
        width: 240,
        height: 42,
        x: 60,
        y: 113,
        scale: 1.0,
        rotationInDegrees: 0.0,
        fixedPosition: false,
      },
      mobileHintsQuery: 'mobileHints-jatmrd801',
      componentType: 'wysiwyg.viewer.components.inputs.TextInput',
      style: {
        type: 'TopLevelStyle',
        id: 'style-jausp2n6',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        style: {
          properties: {
            'alpha-bg': '1',
            'alpha-bgd': '1',
            'alpha-bge': '1',
            'alpha-bgf': '1',
            'alpha-bgh': '1',
            'alpha-brd': '0.55',
            'alpha-brde': '0.6',
            'alpha-brdf': '1',
            'alpha-brdh': '0.7',
            bg: 'color_11',
            bgd: '#FFFFFF',
            bge: 'color_11',
            bgf: 'color_11',
            bgh: 'color_11',
            'boxShadowToggleOn-shd': 'false',
            brd: 'color_15',
            brdd: '#DBDBDB',
            brde: '#FF4040',
            brdf: 'color_18',
            brdh: 'color_15',
            brw: '1',
            fnt: 'font_8',
            rd: '0px',
            shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
            txt: 'color_15',
            txt2: 'color_14',
            txtd: '#DBDBDB',
          },
          propertiesSource: {
            'alpha-bg': 'theme',
            'alpha-bgd': 'theme',
            'alpha-bge': 'theme',
            'alpha-bgf': 'theme',
            'alpha-bgh': 'theme',
            'alpha-brd': 'value',
            'alpha-brde': 'theme',
            'alpha-brdf': 'value',
            'alpha-brdh': 'value',
            bg: 'theme',
            bge: 'theme',
            bgf: 'theme',
            bgh: 'theme',
            brd: 'theme',
            brde: 'theme',
            brdf: 'theme',
            brdh: 'theme',
            fnt: 'theme',
            txt: 'theme',
            txt2: 'theme',
          },
        },
        componentClassName: 'wysiwyg.viewer.components.inputs.TextInput',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.appinputs.AppsTextInputSkin',
      },
      role: 'field_role_text',
      config: {
        crmLabel: t('preset.firstNameCrmLabel'),

        fieldType: 'firstName',
        crmType: 'firstName',
        crmTag: 'main',
      },
      props: {
        type: 'TextInputProperties',
        metaData: { schemaVersion: '1.0', autoGenerated: false },
        required: false,
        textAlignment: 'left',
        textPadding: 12,
        placeholder: t('preset.firstNameCrmLabel'),

        defaultTextType: 'placeholderText',
      },
      data: {
        type: 'TextInput',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        value: '',
        textType: 'text',
        maxLength: 100,
      },
    },
  ],
  skin: 'wysiwyg.viewer.skins.FormContainerSkin',
  layout: {
    width: 900,
    height: 307,
    x: 35,
    y: 18,
    scale: 1.0,
    rotationInDegrees: 0.0,
    fixedPosition: false,
  },
  componentType: 'wysiwyg.viewer.components.FormContainer',
  role: 'form_Role',
  config: {
    preset: 'contact-form',
    labels: ['contacts-contacted_me'],
    emailId: '',
    columns: 3,
  },
})
