import * as _ from 'lodash'
import { FieldPreset } from '../../../../../../constants/field-types'
import translations from '../../../../services/translations'
import { makeGeneralText } from '../../general-fields/definitions/general-text'
import { Field } from '../../field-types-data'
import { CRM_TYPES } from '../../../../../../constants/crm-types-tags'

export const makeCompany = (): Field => {
  const t = translations.t.bind(translations)

  return _.merge(makeGeneralText(), {
    extraData: {
      connectionConfig: {
        crmType: CRM_TYPES.COMPANY,
        crmLabel: t(`fieldTypes.${FieldPreset.COMPANY}`),
      },
      props: { placeholder: t(`fieldTypes.${FieldPreset.COMPANY}`) },
      data: {
        maxLength: 100,
      },
    },
  })
}
