import { FieldPreset } from '../../../../../../constants/field-types'
import { DEFAULT_PLACEHOLDER } from '../constants'
import { FieldCollectionType } from '../../../../../../constants/wixcode'
import { FIELDS } from '../../../../../../constants/roles'
import translations from '../../../../services/translations'
import { COMPONENT_TYPES } from '../../component-types'
import { Field } from '../../field-types-data'
import { CRM_TYPES } from '../../../../../../constants/crm-types-tags'

export const makeGeneralDatePicker = (): Field => {
  const t = translations.t.bind(translations)
  return {
    collectionFieldType: FieldCollectionType.TEXT,
    componentType: COMPONENT_TYPES.DATE_PICKER,
    extraData: {
      role: FIELDS.ROLE_FIELD_DATE,
      connectionConfig: {
        crmType: CRM_TYPES.CUSTOM_FIELD,
        crmLabel: t(`fieldTypes.${FieldPreset.GENERAL_DATE_PICKER}`),
      },
      props: {
        placeholder: t(DEFAULT_PLACEHOLDER),
      },
    },
  }
}
