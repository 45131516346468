import { DEFAULT_CATEGORIES } from 'src/panels/manage-fields-panel/constants/manage-fields-constants'

// TODO: There is two different Field types (the second one in field-types-data)
export interface Field {
  value: string
  name: string
  group: string
  behaviorType: FieldBehaviorType
  customFieldId?: string
  isPremium?: boolean
  dependsOn?: string[]
  tooltip?: string
  subCategory?: DEFAULT_CATEGORIES
  category?: DEFAULT_CATEGORIES
}

export enum FieldBehaviorType {
  INPUT = 'FIELD',
  CONNECTED_ELEMENT = 'CONNECTED_ELEMENT',
}

export enum FieldPreset {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  COMPANY = 'company',
  POSITION = 'position',
  EMAIL = 'email',
  ADDRESS = 'address',
  PHONE = 'phone',
  BIRTHDAY = 'birthday',
  ANNIVERSARY = 'anniversary',
  WEBSITE = 'website',
  GENERAL_TEXT = 'generalText',
  GENERAL_NUMBER = 'generalNumber',
  GENERAL_URL = 'generalURL',
  GENERAL_RADIO_BUTTON = 'generalRadioButton',
  GENERAL_DROP_DOWN = 'generalDropDown',
  GENERAL_CHECKBOX = 'generalCheckbox',
  GENERAL_SINGLE_CHECKBOX = 'generalSingleCheckbox',
  GENERAL_SUBSCRIBE = 'generalSubscribe',
  GENERAL_TEXT_BOX = 'generalTextBox',
  GENERAL_UPLOAD_BUTTON = 'generalUploadButton',
  GENERAL_DATE_PICKER = 'generalDatePicker',
  GENERAL_RATING = 'generalRating',
  CRM_TEXT = 'crmText',
  CRM_NUMBER = 'crmNumber',
  CRM_URL = 'crmURL',
  CRM_DATE = 'crmDate',
  REGISTRATION_FORM_LOGIN_EMAIL = 'regForm_loginEmail',
  REGISTRATION_FORM_PASSWORD = 'regForm_password',
  REGISTRATION_FORM_CHECKBOX_AGREE_TERMS = 'regForm_checkboxAgreeTerms',
  REGISTRATION_FORM_CHECKBOX_JOIN_COMMUNITY = 'regForm_checkboxJoinCommunity',
  NONE = '',
}

export enum ConnectedElementPreset {
  REGISTRATION_FORM_LINK_TO_LOGIN = 'regForm_linkToLoginDialog',
  REGISTRATION_FORM_TERMS_OF_USE = 'regForm_emptyLinkTou',
  REGISTRATION_FORM_PRIVACY_POLICY = 'regForm_emptyLinkPrivacy',
  REGISTRATION_FORM_CODE_OF_CONDUCT = 'regForm_emptyLinkCoc',
}

export const CUSTOM_TYPES_BY_FIELD_TYPE = {
  [FieldPreset.CRM_TEXT]: ['Text'],
  [FieldPreset.CRM_DATE]: ['Number'],
  [FieldPreset.CRM_URL]: ['URL'],
  [FieldPreset.CRM_DATE]: ['Date'],
  [FieldPreset.GENERAL_TEXT]: ['Text'],
  [FieldPreset.GENERAL_NUMBER]: ['Number'],
  [FieldPreset.GENERAL_URL]: ['URL'],
  [FieldPreset.GENERAL_RADIO_BUTTON]: ['Text'],
  [FieldPreset.GENERAL_DROP_DOWN]: ['Text'],
  [FieldPreset.GENERAL_TEXT_BOX]: ['Text'],
  [FieldPreset.GENERAL_UPLOAD_BUTTON]: [],
  [FieldPreset.GENERAL_DATE_PICKER]: ['Date'],
  [FieldPreset.GENERAL_SINGLE_CHECKBOX]: ['Text'],
  [FieldPreset.GENERAL_RATING]: ['Text'],
  [FieldPreset.GENERAL_SUBSCRIBE]: ['Text'],
  [FieldPreset.GENERAL_CHECKBOX]: ['Text'],
}
