import { FieldPreset } from '../../../../../constants/field-types'
import { makeFirstName } from './definitions/first-name'
import { makeLastName } from './definitions/last-name'
import { makeCompany } from './definitions/company'
import { makePosition } from './definitions/position'
import { makeEmail } from './definitions/email'
import { makeAddress } from './definitions/address'
import { makePhone } from './definitions/phone'
import { makeBirthday } from './definitions/birthady'
import { makeAnniversary } from './definitions/anniversary'
import { makeWebsite } from './definitions/webstie'
import { makeCrmText } from './definitions/crm-txt'
import { makeCrmNumber } from './definitions/crm-number'
import { makeCrmURL } from './definitions/crm-url'
import { makeCrmDate } from './definitions/crm-date'

export const recommendedFields = () => ({
  [FieldPreset.FIRST_NAME]: makeFirstName(),
  [FieldPreset.LAST_NAME]: makeLastName(),
  [FieldPreset.COMPANY]: makeCompany(),
  [FieldPreset.POSITION]: makePosition(),
  [FieldPreset.EMAIL]: makeEmail(),
  [FieldPreset.ADDRESS]: makeAddress(),
  [FieldPreset.PHONE]: makePhone(),
  [FieldPreset.BIRTHDAY]: makeBirthday(),
  [FieldPreset.ANNIVERSARY]: makeAnniversary(),
  [FieldPreset.WEBSITE]: makeWebsite(),
  [FieldPreset.CRM_TEXT]: makeCrmText(),
  [FieldPreset.CRM_NUMBER]: makeCrmNumber(),
  [FieldPreset.CRM_URL]: makeCrmURL(),
  [FieldPreset.CRM_DATE]: makeCrmDate(),
})
