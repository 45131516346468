export enum SuccessActionTypes {
  SHOW_MESSAGE = 'SHOW_MESSAGE',
  LINK = 'LINK',
}

export enum SecondsToResetDefaults {
  MIN = 3,
  MAX = 10,
}

export const VISIBLE_LINK_PANEL_SECTIONS = {
  PageLink: true,
  AnchorLink: false,
  NoLink: true,
  PhoneLink: false,
  ExternalLink: false,
  EmailLink: false,
  DocumentLink: false,
  LoginToWixLink: false,
  EdgeAnchorLinks: false,
  PopupLink: true,
}
