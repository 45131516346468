import translations from '../services/translations'
import { ROLE_FORM } from '../../../constants/roles'
import { GFPP } from './manifest-commons'

export const createHiddenMessageManifest = () => ({
  relatedToRole: ROLE_FORM,
  displayName: translations.t('manifest.hiddenMessage.displayName'),
  behavior: { duplicatable: false },
  confirmOnDelete: {
    title: translations.t('manifest.hiddenMessage.confirmOnDelete.title'),
    contentText: translations.t('manifest.hiddenMessage.confirmOnDelete.contentText'),
  },
  gfpp: {
    desktop: {
      iconButtons: {
        connect: GFPP.REMOVE,
      },
    },
  },
})
