import { FIELDS } from '../../../../../../constants/roles'
import { COMPONENT_TYPES } from '../../component-types'
import { ConnectedElementPreset } from '../../../../../../constants/field-types'
import translations from '../../../../services/translations'
import { Field } from '../../field-types-data'

export const makeCodeOfConduct = (): Field => {
  const t = translations.t.bind(translations)

  return {
    collectionFieldType: false,
    componentType: COMPONENT_TYPES.RICH_TEXT,
    extraData: {
      role: FIELDS.ROLE_FIELD_REGISTRATION_FORM_CODE_OF_CONDUCT,
      data: {
        text: `<p class="font_8"><span>${t(
          `fieldTypes.${ConnectedElementPreset.REGISTRATION_FORM_CODE_OF_CONDUCT}`
        )}</span></p>`,
      },
    },
  }
}
