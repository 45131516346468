import { isAnyField, isInputField, undoable, withBi } from '../utils'
import { EVENTS } from '../../../constants/bi'
import { ComponentRef, FormField } from '../api-types'
import * as _ from 'lodash'
import CoreApi from '../core-api'
import { FIELDS_ROLES, ROLE_FORM, ROLE_MESSAGE, ROLE_SUBMIT_BUTTON } from '../../../constants/roles'
import { FieldBehaviorType, FieldPreset } from '../../../constants/field-types'
import { FormPreset } from '../../../constants/form-types'
import { createSuffixedName } from '../../../utils/utils'
import { createField } from '../services/form-service'
import { GROUP_COMPONENT, MOBILE_CONTAINER } from './consts/container-types'

export default class FeildSettingsApi {
  private biLogger: any
  private boundEditorSDK: any
  private coreApi: CoreApi
  private remoteApi: any
  private experiments: any

  constructor(boundEditorSDK, coreApi: CoreApi, remoteApi, { biLogger, experiments }) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.biLogger = biLogger
    this.remoteApi = remoteApi
    this.experiments = experiments
  }

  private async _getAncestorsWithTypes(
    componentRef,
    types: string[]
  ): Promise<{ type: string; componentRef: ComponentRef }[]> {
    const anscestors = await this.boundEditorSDK.components.getAncestors({ componentRef })
    const ancestorsWithTypes = await Promise.all<{ type: string; componentRef: ComponentRef }>(
      anscestors.map(async (componentAncestor: ComponentRef) => ({
        type: await this.boundEditorSDK.components.getType({ componentRef: componentAncestor }),
        componentRef: componentAncestor,
      }))
    )

    return ancestorsWithTypes.filter(ancestor => types.some(type => ancestor.type === type))
  }

  private async _getAllAncestorsWithTypes(componentRefs: ComponentRef[]) {
    const anscestorsOfComponents = await Promise.all(
      componentRefs.map(async componentRef => {
        const ancestors = await this.boundEditorSDK.components.getAncestors({ componentRef })

        return {
          componentRef,
          ancestors,
        }
      })
    )

    const allAncestors = anscestorsOfComponents.reduce<ComponentRef[]>(
      (accumulator: ComponentRef[], { ancestors }) => accumulator.concat(ancestors),
      []
    )

    const ancestorsWithTypes = await this.boundEditorSDK.components.get({
      componentRefs: allAncestors,
      properties: ['componentType'],
    })

    return anscestorsOfComponents.map(({ componentRef, ancestors }) => ({
      componentRef,
      ancestors: ancestors.map(ancestorComponentRef =>
        ancestorsWithTypes.find(
          ancestorWithType => ancestorComponentRef.id === ancestorWithType.componentRef.id
        )
      ),
    }))
  }

  private _sumOffsetsWithMap(containers: ComponentRef[], componentsLayoutMap) {
    const containersOffset = containers.reduce(
      (offsetAccumulator, currentValue) => {
        const containerLayout = componentsLayoutMap[currentValue.id].layout

        return {
          x: offsetAccumulator.x + containerLayout.x,
          y: offsetAccumulator.y + containerLayout.y,
        }
      },
      { x: 0, y: 0 }
    )

    return containersOffset
  }

  private async _sumOffsets(containers: ComponentRef[]) {
    const containersOffset = await containers.reduce(
      async (offsetAccumulatorPromise, currentValue) => {
        const offsetAccumulator = await offsetAccumulatorPromise
        const containerLayout = await this.boundEditorSDK.components.layout.get({
          componentRef: currentValue,
        })

        return {
          x: offsetAccumulator.x + containerLayout.x,
          y: offsetAccumulator.y + containerLayout.y,
        }
      },
      Promise.resolve({ x: 0, y: 0 })
    )

    return containersOffset
  }

  /**
   * A test implementation that uses fewer editor API calls
   * @param componentRef
   * @param param1
   */
  private async _getFieldsSortByXY(
    componentRef: ComponentRef,
    { allFieldsTypes } = { allFieldsTypes: false }
  ) {
    const getRawFields = async () => {
      const { controllerRef } = await this.coreApi.getComponentConnection(componentRef)
      const children = await this.boundEditorSDK.controllers.listConnectedComponents({
        controllerRef,
      })

      return this._getFields(children.filter(x => !!x), allFieldsTypes)
    }

    const rawFields = await getRawFields()
    const allAncestors = await this._getAllAncestorsWithTypes(
      rawFields.map(field => field.componentRef)
    )
    const ancestorsMap = _.keyBy(allAncestors, 'componentRef.id')
    const allComponents = allAncestors
      .reduce(
        (acc, current) => acc.concat(current.ancestors, [{ componentRef: current.componentRef }]),
        []
      )
      .map(el => el.componentRef)
    const allComponentsLayout = await this.boundEditorSDK.components.get({
      componentRefs: allComponents,
      properties: ['layout'],
    })
    const componentsLayoutMap = _.keyBy(allComponentsLayout, 'componentRef.id')

    const fields = rawFields.map(field => {
      const parentContainers = ancestorsMap[field.componentRef.id].ancestors.filter(ancestor =>
        [MOBILE_CONTAINER, GROUP_COMPONENT].some(type => type === ancestor.componentType)
      )
      const fieldLayout = componentsLayoutMap[field.componentRef.id].layout || {
        x: 0,
        y: 0,
        height: 0,
        width: 0,
      }

      if (parentContainers.length > 0) {
        const containersOffset = this._sumOffsetsWithMap(
          parentContainers.map(container => container.componentRef),
          componentsLayoutMap
        )

        fieldLayout.x += containersOffset.x
        fieldLayout.y += containersOffset.y
      }

      const { x, y, height, width } = fieldLayout
      return _.merge({ x, y, height, width }, field)
    })

    return _.sortBy(fields, ['y', 'x'])
  }

  public async getFieldsSortByXY(
    componentRef: ComponentRef,
    { allFieldsTypes } = { allFieldsTypes: false }
  ) {
    if (this.experiments.enabled('specs.cx.FormBuilderFewerAPICalls')) {
      return this._getFieldsSortByXY(componentRef, { allFieldsTypes })
    }

    const getRawFields = async () => {
      const { controllerRef } = await this.coreApi.getComponentConnection(componentRef)
      const children = await this.boundEditorSDK.controllers.listConnectedComponents({
        controllerRef,
      })
      const rawFieldsPromises: Array<Promise<FormField>> = children.map(child =>
        this.getField(child, allFieldsTypes)
      )
      return Promise.all(rawFieldsPromises)
    }

    const rawFields = await getRawFields()
    const fields = await Promise.all(
      rawFields.filter(x => !!x).map(async field => {
        const parentContainers = await this._getAncestorsWithTypes(field.componentRef, [
          MOBILE_CONTAINER,
          GROUP_COMPONENT,
        ])
        const fieldLayout = (await this.boundEditorSDK.components.layout.get({
          componentRef: field.componentRef,
        })) || { x: 0, y: 0, height: 0, width: 0 }

        if (parentContainers.length > 0) {
          const containersOffset = await this._sumOffsets(
            parentContainers.map(container => container.componentRef)
          )

          fieldLayout.x += containersOffset.x
          fieldLayout.y += containersOffset.y
        }

        const { x, y, height, width } = fieldLayout
        return _.merge({ x, y, height, width }, field)
      })
    )
    return _.sortBy(fields, ['y', 'x'])
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.fieldSettingsPanel.VALUE_UPDATED })
  public async updateCrmLabel(componentRef: ComponentRef, crmLabel: string, _biData = {}) {
    const {
      config: { collectionFieldKey },
      controllerRef,
    } = await this.coreApi.getComponentConnection(componentRef)
    await this.coreApi.setComponentConnection(componentRef, { crmLabel })

    const updateCollection = async () => {
      const collectionId = await this._getCollectionId(controllerRef)
      if (!collectionId) {
        return
      }
      return this.coreApi.collectionsApi.updateField(collectionId, collectionFieldKey, crmLabel)
    }

    return updateCollection()
  }

  @undoable()
  public changeQuestion(componentRef: ComponentRef, question, fieldType) {
    switch (fieldType) {
      case FieldPreset.GENERAL_UPLOAD_BUTTON:
        return this._changeUploadFileLabel(componentRef, question)
      case FieldPreset.GENERAL_RADIO_BUTTON:
      case FieldPreset.GENERAL_CHECKBOX:
      case FieldPreset.GENERAL_SINGLE_CHECKBOX:
      case FieldPreset.GENERAL_SUBSCRIBE:
      case FieldPreset.REGISTRATION_FORM_CHECKBOX_AGREE_TERMS:
        return this._changeLabel(componentRef, question)
      default:
        return this._changePlaceholder(componentRef, question)
    }
  }

  @undoable()
  public async changeLabel(componentRef: ComponentRef, label: string) {
    return this._changeLabel(componentRef, label)
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.fieldSettingsPanel.VALUE_UPDATED })
  public async showLabelChanged(componentRef: ComponentRef, showLabel: boolean, _biData = {}) {
    if (showLabel) {
      const {
        config: { label },
      } = await this.coreApi.getComponentConnection(componentRef)

      await this.boundEditorSDK.components.data.update({
        componentRef,
        data: { label },
      })
    } else {
      await this.boundEditorSDK.components.data.update({
        componentRef,
        data: { label: '' },
      })
    }
  }

  @undoable()
  public changeUploadFileLabel(componentRef: ComponentRef, buttonLabel) {
    this._changeUploadFileLabel(componentRef, buttonLabel)
  }

  @undoable()
  public changePlaceholder(componentRef: ComponentRef, placeholder: string) {
    return this._changePlaceholder(componentRef, placeholder)
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.fieldSettingsPanel.TOGGLE_REQUIRED_FIELD })
  public changeRequired(componentRef: ComponentRef, required: boolean, _biData = {}) {
    return this.boundEditorSDK.components.properties.update({
      componentRef,
      props: { required },
    })
  }

  @undoable()
  public updatedCheckedByDefault(componentRef, checked) {
    return this.boundEditorSDK.components.data.update({ componentRef, data: { checked } })
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.fieldSettingsPanel.SELECT_FIELD_TO_CONNECT })
  public setComponentConnection(connectToRef: ComponentRef, connectionConfig, _biData = {}) {
    return this.coreApi.setComponentConnection(connectToRef, connectionConfig)
  }

  public getCustomFields() {
    return this.remoteApi.getCustomFields()
  }

  public createCustomField(field) {
    return this.remoteApi.createCustomField(field)
  }

  private async _getFields(
    componentRefs: ComponentRef[],
    allFieldsTypes: boolean = false
  ): Promise<FormField[]> {
    if (componentRefs.length === 0) {
      return []
    }

    const isValidFieldPred: (role: string) => boolean = allFieldsTypes ? isAnyField : isInputField

    const components = await this.boundEditorSDK.components.get({
      componentRefs: componentRefs,
      properties: ['props', 'data', 'connections'],
    })

    const fields = await Promise.all<FormField>(
      components.map(async component => {
        const { componentRef } = component
        const {
          config: {
            crmLabel,
            crmType,
            crmTag,
            customFieldId,
            fieldType,
            collectionFieldKey,
            collectionFieldType,
            label: labelFromConnection,
          },
          role,
        } = component.connections.find(connection => connection.isPrimary)

        if (!isValidFieldPred(role)) {
          return null
        }

        const {
          props: { placeholder, required },
          data: { buttonLabel, label: labelFromData, checked },
        } = component

        const label = labelFromData || labelFromConnection
        const defaultLabel = this._getDefaultLabel({
          buttonLabel,
          label,
          placeholder,
          fieldType,
        })

        await this._updateLabelConnection({
          componentRef,
          label: labelFromData,
          defaultLabel,
          labelFromConnection,
        })

        const question =
          this._getQuestion({ buttonLabel, label, placeholder, fieldType }) || placeholder

        return {
          question,
          componentRef,
          crmLabel,
          required,
          crmType,
          crmTag,
          fieldType,
          customFieldId,
          collectionFieldKey,
          collectionFieldType,
          checked,
          role,
          label: label || defaultLabel,
          placeholder,
          showLabel: !!labelFromData,
          buttonLabel,
        }
      })
    )

    return fields.filter(x => !!x)
  }

  public async getField(
    componentRef: ComponentRef,
    allFieldsTypes: boolean = false
  ): Promise<FormField> {
    const {
      config: {
        crmLabel,
        crmType,
        crmTag,
        customFieldId,
        fieldType,
        collectionFieldKey,
        collectionFieldType,
        label: labelFromConnection,
      },
      role,
    } = await this.coreApi.getComponentConnection(componentRef)

    const isValidFieldPred: (role: string) => boolean = allFieldsTypes ? isAnyField : isInputField
    if (!isValidFieldPred(role)) {
      return null
    }

    const {
      props: { placeholder, required },
      data: { buttonLabel, label: labelFromData, checked },
    } = await this._getFieldPropertiesAndData(componentRef)

    const label = labelFromData || labelFromConnection
    const defaultLabel = this._getDefaultLabel({
      buttonLabel,
      label,
      placeholder,
      fieldType,
    })

    await this._updateLabelConnection({
      componentRef,
      label: labelFromData,
      defaultLabel,
      labelFromConnection,
    })

    const question = this._getQuestion({
      buttonLabel,
      label,
      placeholder,
      fieldType,
    })

    return {
      question,
      componentRef,
      crmLabel,
      required,
      crmType,
      crmTag,
      fieldType,
      customFieldId,
      collectionFieldKey,
      collectionFieldType,
      checked,
      role,
      label: label || defaultLabel,
      placeholder,
      showLabel: !!labelFromData,
      buttonLabel,
    }
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.manageFieldsPanel.DUPLICATE_FIELD })
  public async duplicateField(
    componentRef: ComponentRef,
    field: FormField,
    preset: FormPreset,
    { extraData, commonStyles },
    _biData = {}
  ) {
    const [
      {
        style,
        data,
        layout: { height, width },
      },
    ] = await this.boundEditorSDK.components.get({
      componentRefs: [field.componentRef],
      properties: ['style', 'data', 'layout'],
    })
    const { connectToRef, controllerRef } = await this._addField(componentRef, preset, {
      fieldType: field.fieldType,
      extraData: _.merge({}, extraData, {
        data,
        layout: { height, width },
      }),
      commonStyles,
    })
    await this.boundEditorSDK.components.style.update({
      componentRef: connectToRef,
      style: _.get(style, 'style.properties'),
    })
    return { connectToRef, controllerRef }
  }

  @undoable()
  @withBi({
    startEvid: EVENTS.PANELS.addFieldPanel.SELECT_FIELD_TO_ADD,
    endEvid: EVENTS.PANELS.addFieldPanel.ADD_FIELD_COMPLETE,
  })
  public async addField(
    componentRef: ComponentRef,
    preset: FormPreset,
    { fieldType, extraData, commonStyles, fieldBehaviorType = FieldBehaviorType.INPUT },
    _biData = {}
  ) {
    return this._addField(componentRef, preset, {
      fieldType,
      extraData,
      commonStyles,
      fieldBehaviorType,
    })
  }

  private async _findNewFieldLayout(componentRef: ComponentRef) {
    const childLayouts = await this.coreApi.layout.getChildrenLayouts(componentRef, FIELDS_ROLES)
    const lastLayout: any = _.maxBy(childLayouts, (field: any) => field.y)
    return {
      x: lastLayout ? lastLayout.x : 60,
      y: lastLayout ? lastLayout.y + lastLayout.height + 32 : 60,
    }
  }

  private async _addField(
    componentRef: ComponentRef,
    preset,
    { fieldType, extraData, commonStyles, fieldBehaviorType = FieldBehaviorType.INPUT }
  ) {
    const {
      controllerRef,
      config: { collectionId, theme },
    } = await this.coreApi.getComponentConnection(componentRef)
    const layout = await this._findNewFieldLayout(componentRef)
    const fields = await this.getFieldsSortByXY(componentRef)
    if (fieldBehaviorType === FieldBehaviorType.INPUT) {
      const collectionFieldKey = createSuffixedName(
        _.map(fields, 'collectionFieldKey'),
        _.camelCase(_.get(extraData, 'connectionConfig.crmLabel')),
        ''
      )
      _.set(extraData, 'connectionConfig.collectionFieldKey', collectionFieldKey)
    }
    const field = createField(preset, { fieldType, extraData, commonStyles, theme }, layout)
    const { connectToRef } = await this.coreApi.addComponentAndConnect(
      field,
      controllerRef,
      componentRef
    )
    const fieldLayout = await this.boundEditorSDK.components.layout.get({
      componentRef: connectToRef,
    })

    const submitBtn = async () => {
      const buttons = await this.coreApi.layout.getChildrenLayouts(componentRef, ROLE_SUBMIT_BUTTON)
      const submitBtn: any = buttons[0]
      return submitBtn
        ? this.boundEditorSDK.components.layout.update({
            componentRef: submitBtn.componentRef,
            layout: { y: submitBtn.y + fieldLayout.height + 32 },
          })
        : null
    }
    const boxLayout = async boxRef => {
      const boxLayout = await this.boundEditorSDK.components.layout.get({ componentRef: boxRef })
      return this.boundEditorSDK.components.layout.update({
        componentRef: boxRef,
        layout: { height: boxLayout.height + fieldLayout.height + 32 },
      })
    }
    const message = async () => {
      const messages = await this.coreApi.layout.getChildrenLayouts(componentRef, ROLE_MESSAGE)
      const message: any = messages[0]
      return message
        ? this.boundEditorSDK.components.layout.update({
            componentRef: message.componentRef,
            layout: { y: message.y + fieldLayout.height + 32 },
          })
        : null
    }

    await Promise.all([
      this.coreApi.collectionsApi.addFieldToCollection(collectionId, field.connectionConfig),
      submitBtn(),
      this.boundEditorSDK.components
        .getAncestors({ componentRef })
        .then(ancestors => Promise.all(_.map(ancestors, boxLayout))),
      boxLayout(componentRef),
      message(),
    ])

    return { connectToRef, controllerRef }
  }

  private _changePlaceholder(componentRef: ComponentRef, placeholder) {
    return this.boundEditorSDK.components.properties.update({
      componentRef,
      props: { placeholder },
    })
  }

  private _changeUploadFileLabel(componentRef: ComponentRef, buttonLabel) {
    return this.boundEditorSDK.components.data.update({
      componentRef,
      data: { buttonLabel },
    })
  }

  private async _changeLabel(componentRef: ComponentRef, label: string) {
    await this.boundEditorSDK.components.data.update({
      componentRef,
      data: { label },
    })
    return this.coreApi.setComponentConnection(componentRef, { label })
  }

  private async _getCollectionId(controllerRef) {
    const componentRef = await this.coreApi.findConnectedComponent(controllerRef, ROLE_FORM)
    if (!componentRef) {
      return
    }
    const {
      config: { collectionId },
    } = await this.coreApi.getComponentConnection(componentRef)
    return collectionId
  }

  private async _getFieldPropertiesAndData(componentRef: ComponentRef) {
    const res = await this.boundEditorSDK.components.get({
      componentRefs: componentRef,
      properties: ['props', 'data'],
    })
    return res[0]
  }

  private _updateLabelConnection({ componentRef, label, defaultLabel, labelFromConnection }) {
    if (labelFromConnection) {
      return
    }

    return this.setComponentConnection(componentRef, { label: label || defaultLabel })
  }

  private _getDefaultLabel({ buttonLabel, label, placeholder, fieldType }) {
    switch (fieldType) {
      case FieldPreset.GENERAL_UPLOAD_BUTTON:
        return label || buttonLabel
      case FieldPreset.GENERAL_RATING:
        return
      default:
        return label || _.get(placeholder, 'text') || placeholder
    }
  }

  private _getQuestion({ buttonLabel, label, placeholder, fieldType }) {
    switch (fieldType) {
      case FieldPreset.GENERAL_UPLOAD_BUTTON:
        return buttonLabel
      case FieldPreset.GENERAL_RADIO_BUTTON:
      case FieldPreset.GENERAL_CHECKBOX:
      case FieldPreset.GENERAL_SINGLE_CHECKBOX:
      case FieldPreset.GENERAL_SUBSCRIBE:
      case FieldPreset.REGISTRATION_FORM_CHECKBOX_AGREE_TERMS:
      case FieldPreset.REGISTRATION_FORM_CHECKBOX_JOIN_COMMUNITY:
        return label
      default:
        return placeholder
    }
  }
}
