import { FieldPreset } from '../../../../../../constants/field-types'
import { FieldCollectionType } from '../../../../../../constants/wixcode'
import { FIELDS } from '../../../../../../constants/roles'
import { COMPONENT_TYPES } from '../../component-types'
import translations from '../../../../services/translations'
import { Field } from '../../field-types-data'
import { CRM_TYPES } from '../../../../../../constants/crm-types-tags'

export const makeGeneralUploadButton = (): Field => {
  const t = translations.t.bind(translations)

  return {
    collectionFieldType: FieldCollectionType.IMAGE,
    componentType: COMPONENT_TYPES.FILE_UPLOADER,
    extraData: {
      role: FIELDS.ROLE_FIELD_FILE_UPLOAD,
      connectionConfig: {
        crmType: CRM_TYPES.CUSTOM_FIELD,
        crmLabel: t(`fieldTypes.${FieldPreset.GENERAL_UPLOAD_BUTTON}`),
      },
      data: {
        buttonLabel: t('preset.fileUploadButtonLabel'),
        placeholderLabel: t('preset.fileUploadPlaceholderLabel'),
      },
    },
  }
}
