import 'regenerator-runtime/runtime'
import 'whatwg-fetch'
import { getAppManifest as coreGetAppManifest } from './core/manifests/app-manifest'
import { getAppVersion, getSentryDSN } from '../utils/utils'
import * as _ from 'lodash'
import * as Raven from 'raven-js'
import { EDITOR_EVENTS } from './constans/EVENTS'
import { initApp, startApp } from './editor-ready/editor-ready'
import {
  handleGfppClicked,
  handleComponentDelete,
  handlePublish,
  handleFirstSave,
} from './on-event/on-event'
import { fetcher } from './utils/utils'

Raven.config(getSentryDSN(), {
  logger: 'logger-editor-app',
  release: getAppVersion(),
})

let biLogger
let panelDefinitions: any = {}
let isInitialized = false

const fetchSiteId = fetcher()
export const getSiteId = () => fetchSiteId.getData
export const getBiLogger = () => biLogger
export const getPanelDefinitions = () => panelDefinitions
export const setBiLogger = logger => {
  biLogger = logger
}
export const setPanelDefinitions = definitions => {
  panelDefinitions = definitions
}

export const onEvent = Raven.wrap(
  ({ eventType, eventPayload }: { eventType: EDITOR_EVENTS; eventPayload: any }) => {
    switch (eventType) {
      case EDITOR_EVENTS.GFPP_CLICKED:
        handleGfppClicked(eventPayload)
        break
      case EDITOR_EVENTS.COMPONENT_DELETED:
        handleComponentDelete(eventPayload)
        break
      case EDITOR_EVENTS.SITE_PUBLISHED:
        handlePublish()
        break
      case EDITOR_EVENTS.SITE_FIRST_SAVE:
        handleFirstSave(eventPayload || {})
        break
      default:
        break
    }
  }
)

export const editorReady = Raven.wrap(
  async (editorSDK, appDefinitionId, { firstInstall, origin }) => {
    const msid = await editorSDK.info.getMetaSiteId(appDefinitionId)
    const siteId = await editorSDK.info.getSiteId(appDefinitionId)
    fetchSiteId.resolveData(siteId)
    const withPreset = _.get(origin, 'info.preset')

    Raven.setUserContext({ id: `msid_${msid}` })
    if (!isInitialized && (firstInstall || !origin)) {
      await initApp({ appDefinitionId, editorSDK, msid })
      isInitialized = true
    }
    return startApp(withPreset, origin)
  }
)

export const getAppManifest = Raven.wrap(coreGetAppManifest)
