import { FieldPreset } from '../../../../../../constants/field-types'
import { FieldCollectionType } from '../../../../../../constants/wixcode'
import { FIELDS } from '../../../../../../constants/roles'
import { COMPONENT_TYPES } from '../../component-types'
import translations from '../../../../services/translations'
import { Field } from '../../field-types-data'
import { CRM_TYPES } from '../../../../../../constants/crm-types-tags'

export const makeGeneralSingleCheckbox = (): Field => {
  const t = translations.t.bind(translations)
  return {
    collectionFieldType: FieldCollectionType.TEXT, // convert to boolean when bug is fixed
    componentType: COMPONENT_TYPES.SINGLE_CHECKBOX,
    extraData: {
      role: FIELDS.ROLE_FIELD_CHECKBOX,
      connectionConfig: {
        crmType: CRM_TYPES.CUSTOM_FIELD,
        crmLabel: t(`fieldTypes.${FieldPreset.GENERAL_SINGLE_CHECKBOX}`),
      },
      data: {
        label: t(`preset.${FieldPreset.GENERAL_SINGLE_CHECKBOX}Label`),
      },
    },
  }
}
