import * as _ from 'lodash'
import { makeGeneralDatePicker } from '../../general-fields/definitions/general-date-picker'
import { Field } from '../../field-types-data'
import { CRM_TYPES } from '../../../../../../constants/crm-types-tags'

export const makeCrmDate = (): Field => {
  return _.merge(makeGeneralDatePicker(), {
    extraData: {
      connectionConfig: {
        crmType: CRM_TYPES.CUSTOM_FIELD,
      },
    },
  })
}
