import { FieldPreset, ConnectedElementPreset } from '../../../../../constants/field-types'
import { makeLoginEmail } from './definitions/login-email'
import { makePassword } from './definitions/password'
import { makeCheckboxAgreeTerms } from './definitions/checkbox-agree-terms'
import { makeCheckboxJoinCommunity } from './definitions/checkbox-join-community'
import { makeLinkToLogin } from './definitions/link-to-login'
import { makePrivacyPolicy } from './definitions/privacy-policy'
import { makeCodeOfConduct } from './definitions/code-of-conduct'
import { makeTermsOfUse } from './definitions/terms-of-use'

export const registrationFormFields = () => ({
  [FieldPreset.REGISTRATION_FORM_LOGIN_EMAIL]: makeLoginEmail(),
  [FieldPreset.REGISTRATION_FORM_PASSWORD]: makePassword(),
  [FieldPreset.REGISTRATION_FORM_CHECKBOX_AGREE_TERMS]: makeCheckboxAgreeTerms(),
  [FieldPreset.REGISTRATION_FORM_CHECKBOX_JOIN_COMMUNITY]: makeCheckboxJoinCommunity(),
  [ConnectedElementPreset.REGISTRATION_FORM_LINK_TO_LOGIN]: makeLinkToLogin(),
  [ConnectedElementPreset.REGISTRATION_FORM_PRIVACY_POLICY]: makePrivacyPolicy(),
  [ConnectedElementPreset.REGISTRATION_FORM_CODE_OF_CONDUCT]: makeCodeOfConduct(),
  [ConnectedElementPreset.REGISTRATION_FORM_TERMS_OF_USE]: makeTermsOfUse(),
})
